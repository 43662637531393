// Auto-hide

var siteNavbar = document.getElementById("site-navbar");

if (siteNavbar) {

	var lastScrollTop = 0;

	document.addEventListener("scroll", function () {

		var st = document.documentElement.scrollTop;

		if (Math.abs(st-lastScrollTop) > 5) {
			if (st < lastScrollTop)
				siteNavbar.classList.remove("site-navbar-up");
			else if (st > 100)
				siteNavbar.classList.add("site-navbar-up");
		}

		lastScrollTop = st;

		[].forEach.call(document.getElementsByClassName("dropdown-toggle"), function (element) {
			bootstrap.Dropdown.getOrCreateInstance(element).hide();
		});

		[].forEach.call(document.querySelectorAll('[data-bs-toggle="popover"]'), function (element) {
			bootstrap.Popover.getOrCreateInstance(element).hide()
		});
	});
}
